/**
 * header.js
 * includes logo and nav bar
 *  
 */

// Components
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
//import {Burger as BurgerMenu} from "../components/burgerMenu"
//import { ReactBurgerMenu } from "react-burger-menu"
import { slide as Menu } from 'react-burger-menu'

// Styles
import styles from "./header.module.scss"
import "./header.scss"

// Images
import Logo from "../images/CPLogo.png"
import LogoMid from "../images/CPLogo-Mid.png"
import LogoSmall from "../images/CPLogo-Small.png"

function showSettings (event) {
  event.preventDefault();
}

const Header = ({ siteTitle, menuLinks }) => (
  <header className={styles.header}>
      <div
        style={{
          margin: "0 auto",
          width: 960,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >

        <Link
             to="/"
             style={{
               color: "white",
               textDecoration: "none",
             }}
        >
          <picture className={styles.logo}>
            <source media="(max-width: 670px)" srcset={LogoSmall} size="117px"/>
            <source media="(max-width: 700px)" srcset={LogoMid} size="117px"/>
            <source media="(min-width: 900px)" srcset={Logo} size="312px"/>
            <img src={Logo} alt="Character Planner"/>
          </picture>
        </Link>
        <div>
          <nav>
            <ul className={styles.headerMenu}>
              {menuLinks.map(link => (
                <li
                  key={link.name}
                  style={{
                    listStyleType: `none`,
                    padding: `1rem`,
                  }}
                >
                  <Link style={{ color: `Black` }} to={link.link}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      <Menu right>
        {menuLinks.map(link => (
          <Link style={{color: `#b8b7ad;`}} to={link.link}>
            {link.name}
          </Link>
        ))}
      </Menu>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
